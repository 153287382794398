import React from 'react'
import axios from 'axios'
import _startCase from 'lodash/startCase'

import { retrieveConfig } from '../../config/config'
import { tokenisationPublicKey } from '.'
import { themeBuilder } from '../../utils/theme-builder'
import { sdkCallback } from './sdkCallback'
import ModalContent from './modal-content'
import { receiptDetails } from '../receipt'

export class PaymentModel extends React.Component {
    dataMesh = null
    chargeId = null

    constructor(props) {
        super(props)

        this.state = {
            selectedConfig: null,
        }

        // Initiate this component with defaults
        const runtimeEnvironment = this.state.selectedConfig
        this.state = {
            show: false,
            showGeneralError: false,
            showFailureScreen: false,
            showReceiptScreen: false,
            isLoaded: false,
            paymentFlowMethod: runtimeEnvironment?.paymentFlowMethod ?? null,
            defaultPaymentMethod: runtimeEnvironment?.defaultpaymentMethod ?? null,
            currencyCode: runtimeEnvironment?.currencyCode,
            orderId: '',
            payerName: window.env.REACT_APP_PAYER_NAME,
            payerEmail: window.env.REACT_APP_PAYER_EMAIL,
            payerTelephone: window.env.REACT_APP_PAYER_TELEPHONE,
            merchantId: runtimeEnvironment?.merchantId ?? null,
            configId: runtimeEnvironment?.configId ?? null,
            publicKey: runtimeEnvironment?.sdkPublicKey ?? null,
            showEditConfigUi: false,
            showPaymentScreen: true,
            showOrderFailed: false,
            chargePayload: null,
            chargeId: null,
            formData: null,
            formErrors: {
                code: false,
            },
        }
    }

    // Track if SDK initialization is in progress
    isSdkInitializing = false
    // Track if the component is mounted
    isComponentMounted = true

    async componentDidMount() {
        const selectedConfig = await retrieveConfig()
        this.setState({ selectedConfig })

        // Add event listener for closePaymentModal event
        document.addEventListener('closePaymentModal', this.handleCloseModal)
    }

    handleCloseModal = event => {
        if (event.detail && event.detail.closed) {
            this.setState({ show: false })
        }
    }

    loadSdk(formData) {
        // First ensure isLoaded is false to prevent the timeout error
        this.setState(
            {
                isLoaded: false,
                showEditConfigUi: false,
                chargePayload: null,
                receiptDetails: null,
                showGeneralError: false,
                message: '',
            },
            () => {
                // Remove timeout check and rely on SDK callbacks
                this.loadSdkData(formData)
            },
        )
    }

    loadSdkData(formData) {
        // Retrieve the order details from the merchant backend using Charge API.
        const orderAmount = formData?.amount || 0
        const serviceId = this.state.selectedConfig.merchantId
        const domain = this.state.selectedConfig.domain
        const { payerName, payerEmail, payerTelephone, currencyCode } = this.state

        axios
            .post(window.env.REACT_APP_DEMO_BACKEND_URL + '/order', {
                orderAmount,
                serviceId,
                domain,
                payerName,
                payerEmail,
                payerTelephone,
                currencyCode,
                formData,
            })
            .then(res => {
                this.setState({
                    isLoaded: false,
                    showGeneralError: false,
                    showFailureScreen: false,
                    message: '',
                })
                window.DatameshAPICallback = () => {
                    this.setupSdk(res.data.orderToken)
                }

                // Give it a chance for the order to get created in memory
                window.DatameshAPICallback()
            })
            .catch(e => {
                console.error('Order creation error, SDK can not be created.', e)
                // Set error state if order creation fails
                this.setState({
                    isLoaded: true,
                    showGeneralError: true,
                    showFailureScreen: false,
                    showPaymentScreen: false,
                    message: 'There was an issue creating your order. Please try again later.',
                })
            })
    }

    createdCallback = response => {
        const chargeId = (this.chargeId = response.charge_id)
        this.setState({ showFailureScreen: false, showPaymentScreen: false, showReceiptScreen: false, chargeId })
    }

    initialCallback = response => {
        this.setState({ isLoaded: true })

        if (response.result !== 'success') {
            console.error('INITIATION ERROR - Datamesh was unable to initiate the SDK widget, please refresh and try again.')
        }
    }

    errorCallback = response => {
        console.error('Error occurred in loading the SDK', response)
        // Set isLoaded to true to prevent timeout error message
        this.setState({
            isLoaded: true,
            showGeneralError: false,
            showFailureScreen: false,
            showPaymentScreen: false,
            message: response?.error?.message || 'An error occurred while loading the payment form.',
        })
    }

    paymentCompletedCallback = response => {
        if (response.status) {
            if (response?.status?.status === 'payment.completed') {
                axios
                    .get(window.env.REACT_APP_DEMO_BACKEND_URL + '/payment/receipt/' + this.chargeId)
                    .then(receiptResponse => {
                        const data = receiptResponse.data
                        const paymentType = data.payments[0].payment_instrument.instrument_type

                        sessionStorage.setItem('lastPayMethod', paymentType)

                        this.setState({
                            showReceiptScreen: true,
                            showPaymentScreen: false,
                            receiptDetails: receiptDetails(data),
                        })

                        // Dispatch event to reset the form
                        document.dispatchEvent(new CustomEvent('submittedPayment'))
                        window?.DataMesh.destroy()
                    })
                    .catch(e => {
                        console.error('Order creation error, SDK can not be created.', e)
                    })
            }
        }
    }

    paymentFailedCallback = response => {
        this.setState({
            showFailureScreen: true,
            showPaymentScreen: false,
            message: response?.extra.error.error_message,
        })
    }

    componentWillUnmount() {
        // Remove event listener when component unmounts
        document.removeEventListener('closePaymentModal', this.handleCloseModal)
        // Set mounted flag to false
        this.isComponentMounted = false
    }
    setupSdk(orderToken) {
        // If SDK initialization is already in progress, don't start another one
        if (this.isSdkInitializing) {
            return
        }

        this.isSdkInitializing = true

        // Set a fallback timeout to handle cases where the SDK doesn't call any callbacks
        const sdkFallbackTimeout = window.setTimeout(() => {
            if (!this.state.isLoaded && this.isComponentMounted) {
                console.error('SDK failed to call any callbacks within the timeout period')
                this.setState({
                    isLoaded: true,
                    showGeneralError: true,
                    showFailureScreen: false,
                    showPaymentScreen: false,
                    message: 'There was an issue loading the payment form. Please try again later.',
                })
            }
        }, 30000) // 30 second timeout

        // Ensure the container element exists and is stable before initializing the SDK
        const maxAttempts = 20 // Maximum number of attempts to find the container
        let attempts = 0

        const waitForContainer = () => {
            // If component is unmounted, stop trying
            if (!this.isComponentMounted) {
                this.isSdkInitializing = false
                clearTimeout(sdkFallbackTimeout)
                return
            }

            // Check if we've exceeded the maximum number of attempts
            if (attempts >= maxAttempts) {
                console.error('Container element not found after maximum attempts')
                this.isSdkInitializing = false
                clearTimeout(sdkFallbackTimeout)
                this.setState({
                    isLoaded: true,
                    showGeneralError: true,
                    showFailureScreen: false,
                    showPaymentScreen: false,
                    message: 'There was an issue loading the payment form. Please try again later.',
                })
                return
            }

            attempts++

            const container = document.querySelector('#paymentDmgSdkBox')
            if (container && container.offsetParent !== null) {
                // Check if element is visible in DOM
                // Set initial fixed height to prevent jumping

                // Wait a bit more to ensure the DOM is fully stable
                setTimeout(() => {
                    container.style.minHeight = '493px'
                    this.setState({
                        isLoaded: true,
                    })
                    // Double-check the container is still there
                    const containerCheck = document.querySelector('#paymentDmgSdkBox')
                    if (containerCheck && containerCheck.offsetParent !== null && this.isComponentMounted) {
                        try {
                            let sdkConfig = {
                                container: '#paymentDmgSdkBox',
                                theme: themeBuilder(this.state.selectedConfig),
                                merchant_id: this.state.selectedConfig.merchantId,
                                config_id: this.state.selectedConfig.configId,
                                public_key: this.state.selectedConfig.sdkPublicKey,
                                locale: this.state.selectedConfig.locale || 'en-US',
                                order_token: orderToken,
                                payment_flow_method: this.state.selectedConfig.paymentFlowMethod,
                                tokenisation_public_key: tokenisationPublicKey,
                                paymentMethod: this.state.selectedConfig.defaultPaymentMethod,
                                callback: () =>
                                    sdkCallback(
                                        this.createdCallback,
                                        this.initialCallback,
                                        this.errorCallback,
                                        this.paymentCompletedCallback,
                                        this.paymentFailedCallback,
                                    ),
                            }

                            window.DataMesh.load(sdkConfig)
                        } catch (error) {
                            console.error('Error initializing SDK:', error)
                            clearTimeout(sdkFallbackTimeout)
                            this.setState({
                                isLoaded: true,
                                showGeneralError: true,
                                showFailureScreen: false,
                                showPaymentScreen: false,
                                message: 'An error occurred while initializing the payment form.',
                            })
                        } finally {
                            this.isSdkInitializing = false
                        }
                    } else {
                        this.isSdkInitializing = false
                        clearTimeout(sdkFallbackTimeout)
                    }
                }, 300) // Additional delay to ensure DOM stability
            } else {
                // If container doesn't exist yet, wait and try again
                setTimeout(waitForContainer, 200)
            }
        }

        waitForContainer()
    }

    // Flag to track if toggle is in progress
    isToggleInProgress = false

    // Track the last toggle timestamp to prevent rapid toggling
    lastToggleTimestamp = 0
    // Minimum time between toggles in milliseconds
    minToggleInterval = 1000

    toggle = data => {
        const now = Date.now()
        // Prevent toggling too quickly
        if (now - this.lastToggleTimestamp < this.minToggleInterval) {
            return
        }
        this.lastToggleTimestamp = now

        // Prevent multiple calls to toggle while one is in progress
        if (this.isToggleInProgress) {
            return
        }

        this.isToggleInProgress = true

        // If we're already showing, handle closing
        if (this.state.show) {
            // Properly destroy the SDK when closing the dialog
            if (window.DataMesh && typeof window.DataMesh.destroy === 'function') {
                try {
                    window.DataMesh.destroy()
                } catch (e) {
                    console.error('Error destroying DataMesh SDK:', e)
                }
            }

            this.setState(
                {
                    show: false, // Actually close the modal
                    isLoaded: false,
                    showPaymentScreen: false,
                    showReceiptScreen: false,
                    showFailureScreen: false,
                    showGeneralError: false,
                },
                () => {
                    // Reset the flag after state update is complete
                    setTimeout(() => {
                        this.isToggleInProgress = false
                    }, 300) // Increased timeout to ensure cleanup completes
                },
            )
            return
        }

        // Opening the dialog
        this.setState(
            {
                showReceiptScreen: false,
                showPaymentScreen: true,
                show: true,
                formData: data,
                isLoaded: false,
            },
            () => {
                // Call loadSdk after state has been updated
                if (this.state.showPaymentScreen) {
                    // Increased timeout to ensure DOM is fully updated
                    setTimeout(() => {
                        this.loadSdk(data)
                        // Reset the flag after loadSdk is called
                        setTimeout(() => {
                            this.isToggleInProgress = false
                        }, 300) // Increased timeout
                    }, 300) // Increased timeout
                } else {
                    // Reset the flag if loadSdk is not called
                    this.isToggleInProgress = false
                }
            },
        )
    }

    render() {
        const { show, showReceiptScreen, chargeId, receiptDetails, showFailureScreen, message, showGeneralError, isLoaded } =
            this.state

        if (!show) {
            return null
        }

        return (
            <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div
                        className="relative w-full h-max md:w-auto md:my-6 mx-auto md:max-w-3xl"
                        style={{
                            width: '100%',

                            maxWidth: this.state.selectedConfig.sdkModalSize ? this.state.selectedConfig.sdkModalSize : '470px',
                        }}
                    >
                        <div
                            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                            style={{
                                transition: 'height 0.3s ease-in-out',
                                paddingBottom: '8px',
                                borderBottomLeftRadius: '0.5rem',
                                borderBottomRightRadius: '0.5rem',
                            }}
                        >
                            <ModalContent
                                showReceiptScreen={showReceiptScreen}
                                chargeId={chargeId}
                                receiptDetails={receiptDetails}
                                showFailureScreen={showFailureScreen}
                                message={message}
                                showGeneralError={showGeneralError}
                                isLoaded={isLoaded}
                            />
                        </div>
                    </div>
                </div>
                <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
            </>
        )
    }
}
